.education {
  &_card {
    border: solid 1px transparentize($color: #fff, $amount: 0.9);
    padding: 30px;
  }

  &_title {
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 13px;
    font-weight: 300;
    line-height: 1.69;
    letter-spacing: 0.33px;
    color: #fff;
    padding-bottom: 20px;
    border-bottom: solid 1px transparentize($color: #fff, $amount: 0.9);
  }

  &_icon {}

  &_link {
    margin-top: 20px;
    font-size: 13px;
    font-weight: 300;
    line-height: 1.54;
    letter-spacing: 0.33px;
    color: $loki-green;
    text-align: center;
    text-decoration: underline;
    text-underline-offset: 6px;
  }
}