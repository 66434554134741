@use './variables'as *;
@use "~bootstrap/scss/bootstrap-utilities.scss"as*;

.bot {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.67;
  letter-spacing: 0.45px;
  text-align: center;
  color: #838997;
  margin-top: 180px;

  img {
    position: relative;
    padding-bottom: 50px;
    margin-top: -100px;
  }

  .ram {
    height: 100%;
    padding: 40px;
    @include ramka();

    &::before,
    &::after {
      opacity: 0.8;
    }

    @include media-breakpoint-down(lg) {
      margin-bottom: 80px;
      height: unset;
    }
  }
}