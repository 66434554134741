@use './variables'as *;
@use "~bootstrap/scss/bootstrap-utilities.scss"as*;

.risk {
  padding: 40px 0;
  font-size: 16px;
  font-weight: 300;
  line-height: 2.14;
  letter-spacing: 0.35px;
  color: #838997;
  background-image: linear-gradient(to right, #13141e, #1a1f25);

  &_1 {
    opacity: 0.5;
    -webkit-text-stroke: 1px #838997;
    font-size: 100px;
    font-weight: 800;
    line-height: 0.3;
    color: transparent;
  }
}