.dialog {
    &_header {
        display: flex;
        align-items: center;
        gap: 30px;

        margin-bottom: 60px;

        a,button {
            display: flex;
            align-items: center;
            color: $loki-green;
            gap: 20px;
        }

        &_date {
            font-size: 14px;
            font-weight: 300;
            color: #838997;
            margin-left: auto;
        }

        &_status {
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0.35px;
            color: #ffd800;
            border: solid 2px #ffd800;
            padding: 13px 20px;
            border-radius: 30px;
        }

        @include media-breakpoint-down(lg) {
            margin-top: 40px;
            flex-wrap: wrap;

            &_status {
                flex-basis: 100%;
            }

            &_date {
                flex-basis: 50%;
            }
        }
    }

    &_title {
        font-size: 24px;
        font-weight: 300;
        line-height: 1.5;
        letter-spacing: 0.6px;
        color: #fff;
    }

    &_body {
        padding: 50px 0;

        p {
            font-size: 14px;
            font-weight: 300;
            line-height: 1.71;
            letter-spacing: 0.35px;
            color: #fff;
        }
    }

    &_btn {
        width: 100%;
    }

    &_comment {
        margin-top: 40px;

        &_autor {
            font-size: 24px;
            letter-spacing: 0.6px;
            color: $loki-green;
            display: flex;
            gap: 20px;
            align-items: center;

            &_rank {
                border-radius: 20px;
                padding: 8px 16px;
                background-color: $loki-green;
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 0.3px;
                color: #000;
            }
        }

        &_body {
            margin-top: 40px;
            margin-bottom: 4px;
            padding: 30px;
            background-image: linear-gradient(to left,
                    transparentize($color: rgba(98, 206, 164, 0.5019607843137255), $amount: 0.6) 0%,
                    transparentize($color: #505257, $amount: 0.94) 100%);
            position: relative;

            &::before {
                content: "";
                display: block;
                position: absolute;
                bottom: 100%;
                left: 0;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 30px 0 0 30px;
                border-color: transparent transparent transparent transparentize($color: #505257, $amount: 0.94);
            }

            p {
                margin: 0;
                font-size: 14px;
                font-weight: 300;
                line-height: 1.71;
                letter-spacing: 0.35px;
            }
        }

        &_footer {
            display: flex;
            gap: 30px;
            padding: 30px;
            background-image: linear-gradient(to left,
                    transparentize($color: rgba(98, 206, 164, 0.5019607843137255), $amount: 0.6) 0%,
                    transparentize($color: #505257, $amount: 0.94) 100%);
        }

        &_date {
            margin-right: auto;
            font-size: 16px;
            font-weight: 300;
            letter-spacing: 0.4px;
            color: #838997;
        }

        &_btn {
            font-size: 16px;
            font-weight: 300;
            letter-spacing: 0.4px;
            color: $loki-green;
        }
    }
}