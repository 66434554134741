.affiliate {
    &_dashboard {
        margin: 40px 0;
    }

    &_filter {
        display: flex;
        gap: 20px;

        &_label {
            font-size: 18px;
            font-weight: 300;
            letter-spacing: 0.45px;
            color: #838997;
        }

        &_select {
            border: none;
            background: none;
            font-size: 18px;
            font-weight: 300;
            letter-spacing: 0.45px;
            text-align: left;
            color: #fff;
        }
    }

    &_h3 {
        font-size: 36px;
        font-weight: 300;
        letter-spacing: 0.9px;
        color: #fff;
        margin: 40px 0;

        @include media-breakpoint-down(lg) {
            font-size: 24px;
        }
    }

    &_pan {
        border: solid 1px transparentize($color: #fff, $amount: 0.9);
        padding-top: 20px;
        padding-bottom: 20px;
        margin-top: 20px;
        margin-bottom: 60px;
    }

    &_lvl {
        &_label {
            align-items: center;
            display: flex;
            font-size: 30px;
            font-weight: 300;
            letter-spacing: 0.75px;
            color: #fff;
            gap: 30px;

            span {
                color: transparent;
                -webkit-text-stroke: 1px #fff;
                font-size: 60px;
                font-weight: 800;
            }

            &::after {
                content: "";
                display: block;
                flex: 1;
                height: 2px;
                // margin: 0 15px;
                background-image: url('../assets/images/dot.svg');
                background-repeat: repeat-x;
            }

            @include media-breakpoint-up(lg) {
                padding-left: 0;
            }

            @include media-breakpoint-down(lg) {
                font-size: 18px;

                span {
                    font-size: 30px;
                }
            }
        }

        &_value {
            color: $loki-green;
            font-size: 20px;
            font-weight: 300;
            letter-spacing: 0.5px;
            display: flex;
            align-items: center;
        }
    }

    &_col {
        &_label {
            font-size: 13px;
            font-weight: 300;
            letter-spacing: 1.3px;
            color: #838997;
        }

        &_value {
            font-size: 30px;
            font-weight: 300;
            letter-spacing: 0.75px;
            color: #838997;
            text-decoration: dotted underline 2px;
            text-underline-offset: 3px;
            margin-top: 10px;

            .small {
                font-size: 20px;
            }

            &-green {
                color: $loki-green;
                text-decoration: none;
            }

            @include media-breakpoint-down(lg) {
                font-size: 22px;

                .small {
                    font-size: 16px;
                }
            }
        }

        &-lg {
            .affiliate {
                &_col {
                    &_value {
                        font-size: 40px;
                        text-decoration: none;

                        @include media-breakpoint-down(lg) {
                            font-size: 30px;
                        }
                    }
                }
            }
        }

        @include media-breakpoint-down(lg) {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}

.rank {
    align-items: center;
    margin-bottom: 100px;

    &-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 50px;
        @include media-breakpoint-down(md) {
            flex-direction: column;
        }
    }

    &_title {
        font-size: 30px;
        font-weight: 300;
        letter-spacing: 0.75px;
        color: #fff;
    }

    &_condition {
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 0.35px;
        color: #838997;
    }

    span {
        color: $loki-green;
    }

    &_reward {
        font-size: 40px;
        font-weight: 300;
        color: rgba(131, 137, 151, 0.75);
        white-space: nowrap;
    }

    &_btn {
        border: solid 1px $loki-green;
        border-radius: 50px;
        padding: 26px 0;
        width: 100%;
        text-align: center;
        color: $loki-green;

        &.complite {
            background-color: $loki-green;
            color: #000;
        }

        &.inactive {
            background-color: transparent;
            border: 1px solid #838997;
            color: #838997;
        }
    }

    &_progress {
        width: 100%;
        height: 9px;
        border-radius: 5px;
        background-color: transparentize($color: #fff, $amount: 0.94);

        >div {
            border-radius: 5px;
            height: 100%;
            width: 0%;
            background-color: $loki-green;
        }
    }

    &_percent {
        font-size: 30px;
        font-weight: 300;
        letter-spacing: 0.75px;
        text-align: center;
        color: #838997;
    }
}