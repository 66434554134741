.topline {
  &__nav {
    display: flex;
    justify-content: center;
    align-items: center;
    // gap: 40px;
    justify-content: space-between;

    a:hover {
      font-weight: 800;
    }

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  &__reg {
    color: $loki-green;
    display: flex;
    align-items: center;
    gap: 30px;

    img {
      height: 25px;
      width: auto;
    }

    button {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;

      &:hover {
        color: $loki-red;

        img {
          filter: brightness(0) saturate(100%) invert(34%) sepia(48%) saturate(7162%) hue-rotate(336deg) brightness(107%) contrast(87%);
        }
      }
    }

    @include media-breakpoint-down(xxl) {
      gap: 10px;
    }
  }

  &_lc {
    justify-content: flex-end;

    button,
    a {
      display: flex;
      gap: 8px;
      &:not(:last-child) {
        flex-direction: row;

        font-size: 13px;
        font-weight: 300;

        letter-spacing: 1.3px;
        color: #838997;
      }
    }
  }

  &_balance {
    &_title {
      font-size: 12px;
      font-weight: 300;
      letter-spacing: 1.2px;
      color: #838997;
      padding-bottom: 8px;
    }

    &_value {
      font-size: 24px;
      font-weight: 300;
      letter-spacing: normal;
      color: $loki-green;

      span {
        -webkit-text-stroke: 1px #2dbe85;
        font-size: 25px;
        font-weight: 800;
        letter-spacing: 4px;
        color: transparent;
      }
    }
  }

  &__lng {
    -webkit-text-stroke: 1px #2dbe85;
    font-size: 20px;
    font-weight: 800;
    letter-spacing: 4px;
    color: transparent !important;
  }
}

#lc {
  header {
    background-color: transparentize($color: #000000, $amount: 0.88);
  }
}
.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.23) !important;
}