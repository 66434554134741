@use '../../styles/variables'as *;
@use "~bootstrap/scss/bootstrap-utilities.scss"as*;

.input {
    color: $loki-green;
    border: solid 1px transparentize($color: #fff, $amount: 0.75);
    background-color: transparent;
    border-radius: 50px;
    text-align: center;
    padding: 30px 0px;
    width: 100%;
    margin-bottom: 20px;

    @include media-breakpoint-down(lg) {
        margin-bottom: 20px;
    }

    &:focus {
        border-color: $loki-green;
        outline: none;
    }
    &.error {
        border-color: $loki-red;
    }
}