.promotion {
  margin-bottom: 50px;

  &_body {
    padding: 30px;
    background-image: linear-gradient(to left,
        transparentize($color: rgba(98, 206, 164, 0.5019607843137255), $amount: 0.6) 0%,
        transparentize($color: #505257, $amount: 0.94) 100%);
  }

  &_title {
    &:is(h2) {
      font-size: 24px;
      font-weight: 300;
      line-height: 1.5;
      letter-spacing: 0.6px;
      color: $loki-green;
      margin-top: 0;
    }
  }

  &_text {
    &:is(p) {
      font-size: 14px;
      font-weight: 300;
      line-height: 1.71;
      letter-spacing: 0.35px;
      color: #fff;
      margin-bottom: 0;
    }
  }

  &_footer {
    margin-top: 4px;
    padding: 30px;
    background-image: linear-gradient(to left,
        transparentize($color: rgba(98, 206, 164, 0.5019607843137255), $amount: 0.6) 0%,
        transparentize($color: #505257, $amount: 0.94) 100%);
  }

  &_date {
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.4px;
    color: #838997;
  }
}