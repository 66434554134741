.support {
  &_header {
    @include media-breakpoint-down(lg) {
      gap: 30px;
    }
  }

  &_filter,
  &_create {
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 0.5px;
    color: $loki-green;
  }

  &_tabs {
    margin: 30px 0;
    display: flex;
    align-items: center;
    gap: 50px;

    @include media-breakpoint-down(lg) {
      overflow-x: auto;
    }

    &_item {
      font-size: 16px;
      font-weight: 300;
      line-height: 1.38;
      color: #838997;
      cursor: pointer;

      &.active {
        color: $loki-green;
      }
    }
  }

  &_table {
    &_body {
      cursor: pointer;
      @include media-breakpoint-down(lg) {
        >div {
          &:nth-child(3n) {
            border-bottom-width: 40px;
          }
        }
      }
    }

    &_theme {
      padding: 30px;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0.35px;
      color: #fff;

      a {
        color: $loki-green;
      }

      &_wrapper {
        display: flex;
      }

      &>div {
        &:last-child {
          color: #838997;
        }
      }
    }

    &_notification {
      background-color: $loki-green;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 10px;
      margin-left: calc(-0.5 * var(--bs-gutter-x));
    }

    &_status {
      display: flex;
      align-items: center;
      text-align: center;
      padding: 30px 5px;
      color: #fff;
      font-size: 14px;

      &_yelow {
        color: #ffd800;
      }

      &_green {
        color: $loki-green;
      }

      &_red {
        color: #ee3a55;
      }

      @include media-breakpoint-down(lg) {
        padding: 30px;
      }
    }
  }
}