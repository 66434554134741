@use './variables'as *;
@use "~bootstrap/scss/bootstrap-utilities.scss"as*;

.impact {
  background-image: url('../assets/images/fon2.png');
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;

  @include media-breakpoint-up(lg) {
    -webkit-mask-image: linear-gradient(to bottom, transparent 0%, black 15%, black 85%, transparent 100%);
    mask-image: linear-gradient(to bottom, transparent 0%, black 15%, black 85%, transparent 100%);
  }

  h3 {
    font-weight: bold;
    color: $loki-green;
  }

  p {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.56;
    letter-spacing: 0.45px;
    color: #838997;
  }

  &_nuber {
    margin-top: 73px;
    @include text($loki-red);
    font-size: 120px;
    font-weight: 800;
  }

  .col-12 {
    &:not(:last-child) {
      .impact_nuber {
        display: flex;
        align-items: center;

        &::after {
          content: "";
          display: block;
          flex: 1;
          height: 2px;
          margin-left: var(--bs-gutter-x);
          background-image: url('../assets/images/dot.svg');
          background-repeat: repeat-x;
        }
      }
    }
  }
}