$loki-green: #2dbe85;
$loki-red: #ee3a55;

@mixin ramka($color: $loki-green, $reverse: false, $rgrad: right) {
    position: relative;

    &::before,
    &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        transition: 0.5s;
        opacity: 0.25;
        border: solid 1px;
        border-image-slice: 1;
        border-image-source: linear-gradient(to right, #16181f, $color);
        z-index: -1;
    }

    &:before {
        @if $reverse {
            border-image-source: linear-gradient(to $rgrad, #16181f, $color);
            transform: skew(1deg) rotate(1deg);
            border-bottom-width: 0;
            border-top-width: 1px;
            bottom: 40%;
            top: 0;
        }

        @else {
            top: 40%;
            transform: skew(1deg) rotate(1deg);
            border-top-width: 0;
        }
    }

    &:after {
        @if $reverse {
            border-image-source: linear-gradient(to $rgrad, #171820, $color);
            transform: skew(-1deg) rotate(-1deg);
            border-top-width: 0;
            border-bottom-width: 1px;
            top: 40%;
            bottom: 0;
        }

        @else {
            bottom: 40%;
            transform: skew(-1deg) rotate(-1deg);
            border-bottom-width: 0;
        }
    }
}

@mixin text($color, $background: #0f111a) {
    color: $color;
    text-shadow: 4px 4px 0px $background, 5px 5px 0px $color;
}