.logo {
    font-family: ChargerEco;
    font-size: 71.5px;
    font-weight: bold;
    text-align: left;
    color: $loki-green;
    line-height: 1;

    span {
        color: $loki-red;
    }
}

.btn {
    &-green {
        border-radius: 50px;
        background-color: $loki-green;
        padding: 30px 10px;
        width: 100%;
        color: #000000;
        font-size: 16px;
        font-weight: bold;
        &:disabled {
            background-color:rgba(255, 255, 255, 0.25)
        }
    }

    &-trans {
        border-radius: 50px;
        border: solid 2px $loki-green;
        font-size: 18px;
        line-height: 1.67;
        letter-spacing: 0.45px;
        color: $loki-green;
        padding: 30px 60px;
        transition: 0.5s;

        &:hover {
            background-color: $loki-green;
            color: #fff;
        }
    }
    &-close {
        box-sizing: content-box;
        width: 1em;
        height: 1em;
        padding: 0.25em;
        color: #000;
        background: transparent url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3E%3Cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3E%3C/svg%3E") 50%/1em auto no-repeat;
        border: 0;
        border-radius: 0.25rem;
        opacity: .5;
        &-white {
            filter: invert(1) grayscale(100%) brightness(200%);
        }
        &:hover {
            opacity: 1;
        }
    }
}

.h2 {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    font-size: 62px;
    font-weight: bold;
    color: $loki-green;

    @include media-breakpoint-down(lg) {
        font-size: 30px;
    }

    span {
        position: relative;
        z-index: -1;
        margin-left: -60px;
        color: #0f111a;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-stroke: 1px transparent;
        background-image: linear-gradient(to right, #17182000, $loki-green);
        font-size: 160px;

        @include media-breakpoint-down(lg) {
            font-size: 50px;
            margin-left: -20px;
        }
    }
}

.modal-content {
    background-image: linear-gradient(to right, #13141e, #1a1f25);
    color: #fff;
    border-radius: 50px;
    background-clip: unset;
}
.RaEdit-card {
    margin-bottom:3rem;
}