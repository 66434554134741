@use './variables' as *;
@use "~bootstrap/scss/bootstrap-utilities.scss" as*;

.botline {
  color: #fff;

  &_border {
    border-top: solid 1px #2f3037;
  }

  .schedule {
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 24px;
    font-weight: 300;
    letter-spacing: 0.6px;

    @include media-breakpoint-down(lg) {
      font-weight: 90;
      font-size: 15px;
      gap: 10px;
      justify-content: center;
      margin-bottom: 20px;
    }

    span {
      color: $loki-green;
    }
  }

  .slogan {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.67;
    padding-left: 40px;

    @include media-breakpoint-down(lg) {
      font-size: 10px;
      padding-left: 10px;
      text-align: center;
      margin-bottom: 20px;
    }
  }

  .logo {
    font-size: 89px;

    @include media-breakpoint-down(lg) {
      font-size: 40px;
      text-align: center;
      margin-bottom: 20px;
    }
  }

  .gray-text {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.71;
    letter-spacing: 0.35px;
    color: #838997;

    @include media-breakpoint-down(lg) {
      font-size: 9px;
      margin-bottom: 20px;
    }
  }
}

.to-top {
  position: fixed;
  bottom: 10px;
  right: 30px;
  color: #ee3a55;
  font-size: 40px;
  cursor: pointer;

  &:hover {
    text-shadow: 0 0 5px #ee3a55, 0 0 10px #ee3a55, 0 0 15px #ee3a55, 0 0 20px #ee3a55;
  }
}