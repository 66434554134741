@use './variables'as*;
@use "~bootstrap/scss/bootstrap-utilities.scss"as*;

@import "./header";
@import "./sidebar";
@import "./lc";
@import "./education";
@import "./promotions";
@import "./pagination";
@import "./support";
@import "./dialog";
@import "./history";
@import "./affiliate";


a.neon-text {
    display: inline;
    color: #2dbe85;
    font-family: 'Arial', sans-serif;
    color: #2dbe85;
    text-shadow: 0 0 10px #2dbe85, 0 0 20px #2dbe85, 0 0 30px #2dbe85;
    animation: glow 1s ease-in-out infinite alternate;
}

@keyframes glow {
    from {
        text-shadow: 0 0 10px #2dbe85, 0 0 20px #2dbe85, 0 0 30px #2dbe85;
    }

    to {
        text-shadow: 0 0 20px #2dbe85, 0 0 30px #2dbe85, 0 0 40px #2dbe85;
    }
}
