@use './variables'as *;
@use "~bootstrap/scss/bootstrap-utilities.scss"as*;

.line {
  margin-top: 119px;
  text-align: center;

  img {
    height: 100px;
  }

  p {
    margin-top: 33px;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.56;
    letter-spacing: 0.45px;
    color: #838997;
  }

  @include media-breakpoint-up(lg) {
    .col-12 {
      &:not(:last-child) {
        position: relative;

        &::before {
          display: block;
          content: "";
          width: 100px;
          height: 2px;
          opacity: 0.5;
          border-top: dotted 2px #838997;
          position: absolute;
          top: 50px;
          right: -50px;
        }
      }
    }
  }
}