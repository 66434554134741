@use './variables'as *;
@use "~bootstrap/scss/bootstrap-utilities.scss"as*;

.pack {
  margin-top: 129px;
  text-align: center;
  padding-top: 45px;
  @include ramka();

  &:hover {

    &::before,
    &::after {
      opacity: 1;
    }
  }

  p {
    font-size: 14px;
    font-weight: 300;
    line-height: 2.14;
  }

  &_size {
    font-size: 105.5px;
    font-weight: bold;
    @include text($loki-green);
  }

  &_prices {
    font-size: 29px;
    font-weight: bold;
    line-height: 2.98;
    color: #ee3a55;

    del {
      font-size: 17.5px;
      font-weight: 300;
      line-height: 4.94;
      color: #838997;
    }
  }

  .btn-green {
    position: relative;
    margin-bottom: -70px;
    width: 85%;
  }
}