.lc {
  &_body {
    &_btn {
      display: block;
      text-align: center;
      padding: 25px 0px;
      width: 90%;
      margin: auto;

      @include media-breakpoint-down(lg) {
        margin-bottom: 20px;
      }

      &_1 {
        padding: 30px 0px;
        width: 100%;
        transform: translateY(75px);
        position: relative;
      }

      &_copied {
        position: absolute;
        left: 110%;
        top: 50%;
        transform: translateY(-50%);
        color: #fff;

        @include media-breakpoint-down(md) {
          left: 0;
          right: 0;
          top: 110%;
          text-align: center;
        }
      }

      &:hover {
        color: #fff;
      }
    }

    .withdrawal {
      text-align: center;
      font-size: 14px;
      font-weight: 300;
      line-height: 3;
      letter-spacing: 0.7px;
      color: #fff;

      p {
        // font-size: 42px;
        font-weight: 300;
        line-height: 1.71;
        letter-spacing: 1.05px;
        color: #fff;

        span {
          color: $loki-red;
        }
      }

      span {
        color: #838997;
      }

      &_btn {
        background-color: $loki-green;
        border-radius: 50px;
        width: 100%;
        padding: 30px;
        color: #131512;
      }
    }

    .select {
      color: $loki-green;
      border: solid 1px transparentize($color: #fff, $amount: 0.75);
      background-color: transparent;
      border-radius: 50px;
      text-align: center;
      padding: 30px 0px;
      width: 100%;

      select {
        color: #838997;
        width: 90%;
        background: none;
        border: none;
        text-align: center;

        &:focus {
          border-color: $loki-green;
          outline: none;
          color: $loki-green;
        }
      }

      &:focus {
        border-color: $loki-green;
        outline: none;
      }
    }

    input,
    textarea,
    .input {
      color: $loki-green;
      border: solid 1px transparentize($color: #fff, $amount: 0.75);
      background-color: transparent;
      border-radius: 50px;
      text-align: center;
      padding: 30px 20px;
      width: 100%;

      @include media-breakpoint-down(lg) {
        margin-bottom: 20px;
      }

      &:focus {
        border-color: $loki-green;
        outline: none;
      }
    }

    h1 {
      font-size: 36px;
      font-weight: 300;
      letter-spacing: 0.9px;
      color: $loki-green;

      @include media-breakpoint-down(lg) {
        font-size: 25px;
        margin-top: 55px;
      }
    }

    h2 {
      font-size: 28px;
      font-weight: 300;
      letter-spacing: 0.7px;
      color: $loki-green;
      margin-top: 70px;
      margin-bottom: 25px;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0.4px;
      color: #fff;
      margin-bottom: 30px;

      span {
        color: $loki-red;
      }
    }
  }

  &_ramka {
    border: solid 1px transparentize($color: #fff, $amount: 0.94);
    padding: 35px 30px;
  }

  &_par {
    display: flex;
    font-size: 14px;
    font-weight: 300;
    line-height: 5.14;
    letter-spacing: 1.4px;
    color: #fff;

    span {
      color: $loki-green;

      &.red {
        color: $loki-red;
      }
    }

    &_label {
      display: flex;
      flex: 1;
      align-items: center;

      &::after {
        content: "";
        display: block;
        flex: 1;
        height: 2px;
        margin: 0 15px;
        background-image: url('../assets/images/dot.svg');
        background-repeat: repeat-x;
      }
    }

    &_value {
      @include media-breakpoint-down(lg) {
        overflow-x: auto;
      }
    }
    @include media-breakpoint-down(md) {
      line-height: 2;
    }
  }

  &_tabs {
    .nav {
      display: flex;
      list-style: none;
      padding: 0;
      justify-content: center;
      width: max-content;
      background-image: linear-gradient(90deg,
          transparentize($color: rgba(98, 206, 164, 0.5019607843137255), $amount: 0.6) 0%,
          transparentize($color: #505257, $amount: 0.94) 100%);
      border-radius: 50px;
      overflow: hidden;

      @include media-breakpoint-down(lg) {
        margin: 20px 0;
      }

      &-item {
        color: #fff;
        font-size: 14px;
        border-left: solid 2px #0f111a;

        &:first-child {
          border-left: none;
        }
      }

      &-link {
        padding: 25px 40px;

        @include media-breakpoint-down(lg) {
          // width: 100%;
          padding: 15px 10px;
        }

        &.active {
          background-color: $loki-green;
        }
      }
    }

    .fade {
      &:not(.show) {
        display: none;
      }
    }
  }

  &_settings {
    a {
      color: $loki-green;
    }

    &_info {
      margin: 30px 0;
    }

    &_field {
      margin: 30px 0;
    }

    &_label {
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0.35px;
      color: #838997;
      margin-bottom: 20px;
    }

    &_value {
      font-size: 16px;
      letter-spacing: 0.4px;
      color: #fff;
    }

    &_checkbox_wrap {
      align-items: center;

      .lc_settings_checkbox {
        width: 40px;
        height: 40px;
        border-radius: 10px;
        padding: 0;

        &:checked {
          background-color: $loki-green;
          border-color: $loki-green;
        }
      }
    }
  }

  &_lvl {
    display: flex;
    align-items: center;
    padding-bottom: 30px;

    &_numbe {
      align-items: center;
      display: flex;
      flex: 1;
      font-size: 37.5px;
      font-weight: 800;
      letter-spacing: 0.94px;
      color: transparent;
      background-clip: text;
      -webkit-text-stroke: 1px $loki-green;

      @include media-breakpoint-down(lg) {
        font-size: 25.5px;
      }

      &::after {
        content: "";
        display: block;
        flex: 1;
        height: 2px;
        margin: 0 15px;
        background-image: url('../assets/images/dot.svg');
        background-repeat: repeat-x;
      }
    }

    &_value {
      color: $loki-green;
      // -webkit-text-stroke: 1px #838997;
      font-size: 20px;
      font-weight: 300;
      line-height: 4.33;
      letter-spacing: 0.5px;
      color: $loki-green;
    }

    &_inactive {
      .lc_lvl_numbe {
        -webkit-text-stroke: 1px #838997;
      }

      .lc_lvl_value {
        color: #838997;
      }
    }
  }

  &_table {
    &_header {
      font-size: 14px;
      font-weight: 300;
      color: #838997;
      margin-bottom: 25px;

      @include media-breakpoint-down(lg) {
        display: none;
      }
    }

    &_body {
      background-image: linear-gradient(to left,
          transparentize($color: rgba(98, 206, 164, 0.5019607843137255), $amount: 0.6) 0%,
          transparentize($color: #505257, $amount: 0.94) 100%);

      >* {
        border-bottom: solid 4px #0f111a;
        border-right: solid 4px #0f111a;
      }
    }

    &_date {
      display: flex;
      align-items: center;
      text-align: center;
      padding: 30px 5px;
      color: #fff;
      font-size: 14px;
      font-weight: 300;

      @include media-breakpoint-down(lg) {
        padding: 30px;
      }
    }
  }
}

.g2a {
  &_title {
    margin: 30px 0;
    font-size: 30px;
    font-weight: 300;
    line-height: 0.8;
    letter-spacing: 0.75px;
    color: rgba(131, 137, 151, 0.5);
  }

  &_button {
    text-align: center;
    padding: 30px;
    background-image: linear-gradient(90deg,
        transparentize($color: rgba(98, 206, 164, 0.5019607843137255), $amount: 0.6) 0%,
        transparentize($color: #505257, $amount: 0.94) 100%);
  }
}

.name {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.29;
  letter-spacing: 0.35px;
  color: #838997;
  padding-bottom: 55px;
  margin-bottom: 55px;
  border-bottom: solid 1px #2f3037;

  span {
    color: #fff;
  }

  button {
    text-decoration: dotted underline $loki-green;
    text-underline-offset: 2px;
    font-size: 20px;
    font-weight: 300;
    line-height: 1.5;
    letter-spacing: 0.5px;
    color: $loki-green;
    padding: 0;
    margin-top: 45px;
  }
}

.balance {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 1px;
  margin: 10px 0;

  @include media-breakpoint-down(lg) {
    flex-direction: column;
    gap: 20px;
    font-size: 14px;
  }

  &_pay {
    display: flex;
    flex: 1;
    align-items: center;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.7px;
    color: #838997;

    &::after {
      content: "";
      display: block;
      flex: 1;
      height: 2px;
      margin: 0 15px;
      background-image: url('../assets/images/dot.svg');
      background-repeat: repeat-x;
    }
  }

  &_inf {
    border: solid 1px transparentize($color: #fff, $amount: 0.94);
    color: #fff;
    padding: 0px 41px;
    padding-top: 30px;
  }

  &_tg {
    font-size: 20px;
    font-weight: 300;
    line-height: 1.2;
    letter-spacing: 1px;
    color: $loki-green;
    padding-bottom: 50px;
    align-items: center;
    display: flex;
    gap: 20px;
    width: max-content;
  }

  select {
    color: $loki-green;
    border: none;
    outline: none;
    box-sizing: none;
    background: none;

    @include media-breakpoint-down(lg) {
      margin-bottom: 20px;
    }
  }
}

.referal_active {
  color: $loki-green;
}