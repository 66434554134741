@use './variables'as*;

@import './fonts';
@import "~bootstrap/scss/bootstrap-grid.scss";
@import "~bootstrap/scss/bootstrap-utilities.scss";
@import "~bootstrap/scss/bootstrap-reboot.scss";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/toasts";

@import './global';


body {
    background-color: #0f111a;
    font-family: Acrom;
}
#main {
    background-image: url('../assets/images/fon.png');
    background-size: 100% auto;
    background-repeat: no-repeat;
}

a {
    text-decoration: none;
    // font-size: 15px;
    letter-spacing: 0.75px;
    color: #838997;

    &:hover {
        color: $loki-green;
    }
}

button {
    background: none;
    border: none;
    color: unset;
}

img {
    max-width: 100%;
}

p {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.67;
    color: #fff;
    margin-top: 15px;
}

section {
    margin: 150px 0;

    @include media-breakpoint-down(lg) {
        margin: 100px 0;
    }
}