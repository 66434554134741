@use './variables'as *;
@use "~bootstrap/scss/bootstrap-utilities.scss"as*;

.accept {
  text-align: center;
  background-image: url('../assets/images/fon2.png');
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 100px;
  padding-bottom: 30px;
  -webkit-mask-image: linear-gradient(to bottom, transparent 0%, black 15%, black 85%, transparent 100%);
  mask-image: linear-gradient(to bottom, transparent 0%, black 15%, black 85%, transparent 100%);

  h2 {
    -webkit-text-stroke: 1px #2dbe85;
    color: transparent;
    font-size: 72px;
    font-weight: bold;
    line-height: 1.01;

    @include media-breakpoint-down(lg) {
      font-size: 40px;
    }

    span {
      font-weight: bold;
      line-height: 1.01;
      color: $loki-green;
    }
  }

  p {
    margin-top: 50px;
    font-size: 30px;
    font-weight: 300;
    line-height: 1.53;
  }

  a {
    font-size: 30px;
    font-weight: 300;
    line-height: 1.53;
    color: $loki-green;
  }

  .btn-trans {
    margin-top: 50px;
  }

  &_2 {
    padding: 50px 0;
    margin: 0;
    background-image: url('../assets/images/fon.png');
    background-position: center;

    p {
      margin-top: 0;
    }

    h2 {
      margin-bottom: 20px;
    }
  }
}