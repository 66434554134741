@use './variables'as *;
@use "~bootstrap/scss/bootstrap-utilities.scss"as*;

.stages {
  align-items: center;
  min-height: 220px;
  margin-top: 100px;
  @include ramka();

  &:nth-child(even) {
    @include ramka($reverse: true);
  }

  h3 {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.25;
    color: $loki-green;
  }

  p {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.67;
    color: #838997;
  }

  &_nuber {
    @include text($loki-red);
    font-size: 90px;
    font-weight: 800;
  }

  @include media-breakpoint-up(lg) {
    &:not(:last-child) {
      .stages_nuber {
        position: relative;

        &::after {
          display: block;
          content: "";
          background-image: url('../assets/images/dot2.svg');
          background-repeat: repeat-y;
          width: 2px;
          height: 200px;
          position: absolute;
          top: 120px;
          left: 65px;
        }
      }
    }
  }
}