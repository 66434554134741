@use './variables' as *;
@use "~bootstrap/scss/bootstrap-utilities.scss" as*;

.baner {
  color: #fff;
  margin-top: 160px;
  margin-bottom: 250px;

  .slogan {
    // margin: 210px 17px 43px 60px;
    margin: 40px 0;
    font-size: 52px;
    font-weight: bold;
    text-align: left;
    color: $loki-green;
  }

  .text {
    font-size: 18px;
    font-weight: 300;
    line-height: 2;
    letter-spacing: 0.45px;
    color: #fff;
    margin: 40px 0;
  }

  @include media-breakpoint-down(lg) {
    .slogan {
      font-size: 30px;
    }

    .text {
      font-size: 16px;
    }
  }

  @include media-breakpoint-down(md) {
    text-align: center;
  }
}