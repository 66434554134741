@use './variables'as *;
@use "~bootstrap/scss/bootstrap-utilities.scss"as*;

.card {
  padding: 30px;
  height: 100%;

  img {
    width: 100%;
    margin-bottom: 36px;
    clip-path: polygon(0 6%, 100% 0, 100% 100%, 0% 100%);
  }

  p {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.67;
    letter-spacing: 0.45px;
    color: #838997;
  }

  @include ramka();

  &_wrap {
    margin-top: 120px;
  }

  &:hover {

    &::before,
    &::after {
      opacity: 1;
    }
  }
}