@use './variables'as *;
@use "~bootstrap/scss/bootstrap-utilities.scss"as*;

.splide {
  margin-top: 85px;
}

.about {
  display: flex;
  align-items: center;
  padding: 0 130px;
  height: 100%;
  
  @include media-breakpoint-down(lg) {
    padding: 0 10px;

    &_img {
      display: none;
    }
  }

  &_img {
    position: relative;
    padding: 50px 0;
    margin-right: -30px;
    z-index: 2;
    max-width: 300px;
  }

  &_text {
    background-color: #171820;
    clip-path: polygon(0 4%, 100% 0, 100% 100%, 0 96%);
    padding: 50px;
    padding-left: 80px;
    flex: 1;

    h3 {
      font-size: 18px;
      font-weight: 300;
      color: #fff;
    }

    p {
      font-size: 12px;
      font-weight: 300;
      line-height: 2;
      color: #838997;
    }
  }
}

.splide__arrow {
  background: none;
}

.splide__pagination {
  bottom: -50px;
  gap: 16px;

  &__page {
    &.is-active {
      transform: scale(2);
      background-color: $loki-green;
    }
  }
}

.my-arrow {
  display: flex;
  align-items: center;
  transition: 0.3s;

  &::before,
  &::after {
    content: "";
    display: block;
    // opacity: 0.5;
    transition: 0.3s;
  }

  &::before {
    width: 12px;
    height: 12px;
    border-width: 0;
    border-color: #838997;
    border-style: solid;
    border-top-width: 2px;
    border-left-width: 2px;
    transform: rotateZ(-45deg);
  }

  &::after {
    width: 50px;
    height: 2px;
    background-color: #838997;
    margin-left: -14px;
  }

  &.reverse {
    flex-direction: row-reverse;

    &::before {
      transform: rotateZ(135deg);
    }

    &::after {
      margin-right: -14px;
    }

    &:hover {
      margin-right: 0;
      margin-left: 30px;
    }
  }

  &:hover {
    margin-right: 30px;

    &::before {
      border-color: $loki-green;
    }

    &::after {
      background-color: $loki-green;
      width: 80px;
    }
  }
}