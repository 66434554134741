@use './variables'as *;
@use "~bootstrap/scss/bootstrap-utilities.scss"as*;

.accordion {

  margin-top: 88px;
}

.accordion-item {
  margin-bottom: 30px;
  background-color: transparent;
  background-image: linear-gradient(to right, #13141e, #1a1f25);
  color: #fff;
}

.accordion-button {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.67;
  color: #fff;
  padding: 40px;

  &:not(.collapsed) {
    border: none;
    box-shadow: none;
    color: $loki-green;
  }

  &::after {
    background-image: url('../assets/images/arrowak.png') !important;
  }
}