.history {
    &_header {
        margin: 50px 0;

        @include media-breakpoint-down(lg) {
            gap: 30px;
        }
    }

    &_filter {
        display: flex;
        align-items: center;
        gap: 20px;
        font-size: 20px;
        font-weight: 300;
        letter-spacing: 0.5px;
        color: $loki-green;
    }

    &_balance {
        font-size: 20px;
        font-weight: 300;
        letter-spacing: 0.5px;
        color: #838997;

        span {
            color: $loki-green;
        }
    }

    &_table {
        &_body {
            @include media-breakpoint-down(lg) {
                >div {
                    &:nth-child(3n) {
                        border-bottom-width: 40px;
                    }
                }
            }
        }

        &_label {
            padding: 30px;
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 0.35px;
            color: #fff;

            span,
            a {
                color: $loki-green;
            }
        }

        &_status {
            padding: 30px;

            &_green {
                color: $loki-green;
            }

            &_red {
                color: $loki-red;
            }

            &_yellow {
                color: #ffd800;
            }
        }
    }
}