@use './variables'as *;
@use "~bootstrap/scss/bootstrap-utilities.scss"as*;

.advantages {
  margin-top: 150px;

  h3 {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.25;
    color: $loki-green;

    @include media-breakpoint-down(lg) {
      margin-top: 20px;
    }
  }

  p {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.67;
    color: #838997;
  }

  .row {
    img {
      margin-top: -30px;
    }

    margin-bottom: 100px;
    @include ramka();
    padding: 0 40px;
    align-items: center;

    &:nth-child(even) {
      flex-direction: row-reverse;
      @include ramka($reverse: true, $rgrad: left);
    }
  }
}