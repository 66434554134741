.pagination {
  display: flex;
  justify-content: center;
  gap: 5px;
  margin-top: 60px;

  &_item {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0.45px;
    color: #838997;
    border: solid 1px transparent;
    cursor: pointer;

    &_next {
      width: unset;
    }

    &.active {
      color: $loki-green;
      border-color: $loki-green;
    }

    &:hover {
      color: $loki-green;
    }

    @include media-breakpoint-down(lg) {
      width: 30px;
      height: 30px;
      font-size: 14px;

      &_next {
        display: none;
      }
    }
  }
}