@use '../../styles/variables'as *;

.logo {
    font-family: ChargerEco;
    font-size: 71.5px;
    font-weight: bold;
    text-align: left;
    color: $loki-green;
    line-height: 1;

    span {
        color: $loki-red;
    }
}